<template>
    <ul class="m-setting01">
        <li class="setting-con01">
            <span class="setting-lab01">台湾现住址</span>
            <textarea @input="changeTextarea($event)" ref="addressRef" :value="personalObj.taiAddress" class="setting-ipt01"></textarea>
        </li>
    </ul>
</template>
<script>
import store from '../../store'
import {mapState} from 'vuex'
export default {
    store,
    data() {
        return {

        }
    },
    mounted() {
        this.$refs.addressRef.style.height = this.$refs.addressRef.scrollHeight + 'px'
    },
    methods: {
        changeTextarea(e) {
            this.$refs.addressRef.style.height = this.$refs.addressRef.scrollHeight + 'px'
            this.$store.commit('modifyPersonalObj', {
                taiAddress: e.target.value
            })
        }
    },
    computed: {
        ...mapState({
            personalObj: state => state.mineInfo.personalObj
        })
    }
}
</script>
<style lang="scss" scoped>
.setting-con01 {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    background: #fff;
}

.setting-ipt01 {
    padding-left: 20px;
    box-sizing: border-box;
    // min-height: 100px;
    line-height: 1.5;
    border: none;
}


</style>